import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FeedbackPage = () => (
    <Layout>
        <SEO title="Lähetä palautetta" description="Kysymyksiä, kommentteja, toivomuksia, lähetä meille palautetta!" />
        <h1 className='pageTitle'>Lähetä meille palautetta</h1>
        <p>
            Kysymyksiä, kommentteja, toivomuksia? Lähetä meille palautetta!<br/><br/>
            <form
                action="https://formspree.io/f/moqydrbd"
                method="POST"
                target="_blank">
                <label>
                    Sähköpostiosoitteesi, jos haluat vastauksen<br/>
                    <input type="email" name="_replyto" style={{width:400}} /><br/><br/>
                </label>
                <label>
                    Koulu tai kaupunki, jota palaute koskee<br/>
                    <input type="text" name="_school" style={{width:400}} required /><br/><br/>
                </label>
                <label>
                    Palautteesi, kysymyksesi, kehitysehdotuksesi<br/>
                    <textarea name="message" style={{width:400}} rows='6' required></textarea>
                </label><br/><br/>                
                <button type="submit">Lähetä</button>
                <input type="hidden" name="_language" value="fi" />
            </form>
        </p>
    </Layout>
)

export default FeedbackPage


